import { Controller } from "stimulus";

export default class extends Controller {

  static get targets() {
    return [
      'weights', 'doseLevels'
    ];
  }

  connect() {
    this.updateDose(this.element.querySelector('.selected'));
  }

  initialize() {
    // Set up event listeners for weight click
    this.element.addEventListener('click', (event) => {
      const weightElement = event.target.closest('.weight-range');
      if (weightElement) {
        console.log('update by weight:', weightElement);
        this.updateDose(weightElement);
        this.updateSelectedWeight(weightElement);
      }
    });
  }

  updateDose(weightElement) {
    const levels = [1, 2, 3, 4, 5];
    levels.forEach(level => {
      const doseSpan = this.doseLevelsTarget.querySelector(`li:nth-child(${level}) .dose`);
      const doseMaxIndicator = this.doseLevelsTarget.querySelector(`li:nth-child(${level}) .max-level-indicator`);
      const doseValue = weightElement.getAttribute(`data-dose-level-${level}`);
      const maxDoseLevel = parseInt(weightElement.getAttribute(`data-dose-level-max`));
      if(level < maxDoseLevel){
        doseMaxIndicator.classList.add('hidden');
      }
      else{
        doseMaxIndicator.classList.remove('hidden');
      }
      doseSpan.textContent = doseValue;
      console.log(doseSpan, doseValue);
    });

    // Update the displayed selected weight
    const selectedWeight = weightElement.querySelector('span').textContent;
    document.getElementById('selected-weight').innerText = selectedWeight;
  }

  updateSelectedWeight(selectedElement) {
    // Remove selected class from all weight elements
    this.element.querySelectorAll('.weight-range').forEach(element => {
      element.classList.remove('selected');
    });
    // Add selected class to the clicked weight element
    selectedElement.classList.add('selected');
  }
}
